/**
 * Alerts based on Bulma
 */
import { _$ } from "/WebInterface/new-ui/assets/js/utils/dom.js";

export const Alert = ({
  type = "alert",
  warn = false,
  animate = true,
  title = "",
  body = "",
  confirm = "Yes",
  cancel = "No",
  style = "card",
  extraClasses = [],
  append = null,
  prepend = null,
  parent = document.body,
  showHeader = true,
  closable = true,
  submitOnEnter = true,
  placeholder = "",
  inputAttrs = {},
  textarea = false,
  appendFooter = null,
  prependFooter = null,
  disableScroll = true,
  animationInClass = "fadeInDown",
  animationOutClass = "fadeOutUp",
  zIndex = "",
  onBeforeShow = () => {},
  onAfterShow = () => {},
  onBeforeHide = () => {},
  onAfterHide = () => {},
}) => {
  return new Promise((resolve, reject) => {
    if (type === "alert") {
      cancel = null;
      confirm = "Ok";
    }
    const uniqueId = utils.getUId();
    const modal = {};
    if (animate) extraClasses.push("animate");
    var root = utils.createElement(
      "div",
      ["modal", "is-active"].concat(extraClasses)
    );

    if (zIndex) {
      root.style.zIndex = zIndex;
    }

    const modalType = style === "card" ? "card" : "content";
    const cardType = warn ? "warning" : "info";
    root.setAttribute("id", "modal_" + uniqueId);
    var background = utils.createElement("div", ["modal-background"]);
    const animation = animate ? ["animate", animationInClass] : [];
    const content =
      style === "card"
        ? utils.createElement("div", ["modal-card"].concat(animation))
        : utils.createElement("div", ["modal-content"].concat(animation));

    const createCardStructure = () => {
      if (showHeader && title) {
        modal.header = utils.createElement("header", [
          "modal-" + modalType + "-head",
        ]);

        // const textColor = cardType == "warning" ? "black" : "white";
        let headerTitle = utils.createElement("p", [
          "modal-" + modalType + "-title",
          "text-primaryMain",
        ]);
        headerTitle.innerHTML = utils.sanitizePure(title);
        modal.header.appendChild(headerTitle);
      }

      modal.body = utils.createElement("section", [
        "modal-" + modalType + "-body",
      ]);
      if (prepend) {
        if (typeof prepend === "string") {
          modal.body.innerHTML = utils.sanitizePure(
            prepend + modal.body.innerHTML
          );
        } else {
          modal.body.appendChild(prepend);
        }
      }
      const bodyLabel = utils.createElement("label", [
        "label",
        "text-primaryMain",
      ]);
      if (typeof body === "string") {
        bodyLabel.innerHTML = utils.sanitizePure(body);
      } else {
        bodyLabel.appendChild(body);
      }
      if (type === "alert" || type === "confirm") {
        modal.body.appendChild(bodyLabel);
      } else if (type === "prompt") {
        modal.body.appendChild(bodyLabel);
        const prnt = utils.createElement("div", ["fields"]);
        const lbl = utils.createElement("label", ["label"]);
        const control = utils.createElement("div", ["control"]);
        const input = textarea
          ? utils.createElement("textarea", ["textarea"], {
              placeholder: placeholder,
              name: "value",
              ...inputAttrs,
            })
          : utils.createElement("input", ["input"], {
              type: "text",
              placeholder: placeholder,
              name: "value",
              ...inputAttrs,
            });
        prnt.appendChild(lbl);
        control.appendChild(input);
        prnt.appendChild(control);
        modal.body.appendChild(prnt);
      }
      if (append) {
        if (typeof append === "string") {
          modal.body.innerHTML += utils.sanitizePure(append);
        } else {
          modal.body.appendChild(append);
        }
      }

      /** @param {HTMLElement} */
      modal.footer = utils.createElement("footer", [
        "modal-" + modalType + "-foot",
        "is-justify-content-end",
      ]);

      if (prependFooter) {
        if (typeof prependFooter === "string") {
          modal.footer.innerHTML = utils.sanitizePure(
            prependFooter + modal.footer.innerHTML
          );
        } else {
          modal.footer.appendChild(prependFooter);
        }
      }
    };

    const close = () => {
      onBeforeHide();
      const continueClose = () => {
        root.classList.remove("is-active");
        document.documentElement.classList.remove("is-clipped");
        _$("html").removeClass("crushftp-opened-modal");
        if (closable || submitOnEnter) {
          document.removeEventListener("keyup", keyupListener);
        }
        root.remove();
        for (var key in modal) {
          delete modal[key];
        }
        onAfterHide();
      };
      if (animate) {
        const modalContent = root.querySelector(".animate");
        modalContent.classList.remove(animationInClass);
        modalContent.classList.add(animationOutClass);
        setTimeout(() => {
          continueClose();
        }, 50);
      } else {
        continueClose();
      }
    };

    const serialize = () => {
      const elems = root.querySelectorAll("input, textarea");
      const values = {};
      elems.forEach((elem) => {
        const elType = elem.getAttribute("type");
        if (elType === "checkbox" || elType === "radio") {
          values[elem.getAttribute("name")] = elem.checked;
        } else values[elem.getAttribute("name")] = elem.value.trim();
      });
      return values;
    };

    const keyupListener = (event) => {
      if (!root.classList.contains("is-active")) {
        return;
      }
      let key = event.key || event.keyCode;
      if (closable) {
        if (key === "Escape" || key === "Esc" || key === 27) {
          resolve(null);
          close();
        }
      }
      if (submitOnEnter) {
        if (key === "Enter" || key === 13) {
          resolve(serialize());
          close();
        }
      }
    };

    /**
     * Go through the provided buttons option and create the buttons.
     * @returns {void}
     */
    const createButtons = () => {
      var defaultButtonOptions = {
        close: true,
        onClick: function () {},
      };

      var confirmOptions = confirm;
      if (typeof confirmOptions === "string") {
        confirmOptions = {
          label: confirmOptions,
          classes: [],
        };
      }
      confirmOptions = { ...defaultButtonOptions, ...confirmOptions };

      var confirmButton = utils.createElement(
        "button",
        ["primary-button", "mr-3"].concat(confirmOptions.classes)
      );
      confirmButton.innerHTML = utils.sanitizePure(confirmOptions.label);
      confirmButton.addEventListener("click", (e) => {
        confirmOptions.onClick(e);
        if (confirmOptions.close) {
          resolve(serialize());
          close();
        }
      });
      modal.footer.appendChild(confirmButton);
      if (cancel) {
        var cancelOptions = cancel;
        if (typeof cancelOptions === "string") {
          cancelOptions = {
            label: cancelOptions,
            classes: [],
          };
        }
        cancelOptions = { ...defaultButtonOptions, ...cancelOptions };

        var cancelButton = utils.createElement(
          "button",
          ["primary-button", "is-bordered"].concat(cancelOptions.classes)
        );
        cancelButton.innerHTML = utils.sanitizePure(cancelOptions.label);
        cancelButton.addEventListener("click", (e) => {
          cancelOptions.onClick(e);

          if (cancelOptions.close) {
            resolve(null);
            close();
          }
        });
        modal.footer.appendChild(cancelButton);
      }
      if (appendFooter) {
        if (typeof appendFooter === "string") {
          modal.footer.innerHTML += utils.sanitizePure(appendFooter);
        } else {
          modal.footer.appendChild(appendFooter);
        }
      }
    };

    createCardStructure();
    createButtons();
    if (modal.header) content.appendChild(modal.header);
    if (modal.body) content.appendChild(modal.body);
    if (modal.footer) content.appendChild(modal.footer);
    root.appendChild(background);
    root.appendChild(content);

    _$(".modal").removeClass("modal-on-top");
    _$(root).addClass("modal-on-top");

    onBeforeShow(root, close);
    _$("html").addClass("crushftp-opened-modal");
    if (disableScroll) document.documentElement.classList.add("is-clipped");
    parent.appendChild(root);
    onAfterShow(root, close);
    const firstInput = root.querySelector("input, textarea");
    try {
      if (firstInput && firstInput.setSelectionRange)
        firstInput.setSelectionRange(-1, -1);
    } catch (error) {}
    root.querySelector("input, textarea, button").focus();
    if (closable || submitOnEnter) {
      document.addEventListener("keyup", keyupListener);
      if (closable) background.addEventListener("click", close.bind(this));
    }
  });
};

export const Prompt = (args) => {
  return Alert({
    type: "prompt",
    ...args,
  });
};

export const Confirm = (args) => {
  return Alert({
    type: "confirm",
    ...args,
  });
};
